import React from 'react';

import {
  AreaMainContainer,
  AreaWrapper,
  AreaMainTitle,
} from '../styledComponents/StyledComponents';

function ServiceArea() {
  return (
    <div id="service_area">
      <AreaMainContainer>
        <AreaWrapper>
          <AreaMainTitle>SERVICE AREA</AreaMainTitle>
          <img
            loading="lazy"
            src="../images/ServiceArea.jpg"
            alt="ServiceAreaMap"
            style={{ width: '55%', height: 'auto' }}
          />
        </AreaWrapper>
      </AreaMainContainer>
    </div>
  );
}

export default ServiceArea;
