import React from 'react';
import { Link } from 'react-scroll';
import {
  HomeMainWrapper,
  HomeMainContainer,
  TitleName,
  SubTitle,
  HomeDescriptionContainer,
  HomePictureContainer,
  Slogan,
  Divider,
  HomeButton,
} from '../styledComponents/StyledComponents';

function Home() {
  return (
    <div id="home">
      <HomeMainContainer>
        <HomeMainWrapper>
          <HomeDescriptionContainer>
            <Divider>_</Divider>
            <Slogan>"Let Olem's Take Care Of Your Electrical Needs"</Slogan>
            <TitleName>Your Premier Electrical Partner</TitleName>
            <SubTitle>
              With over 38 years of expertise in electrical solutions, we
              proudly serve Central and North Jersey across residential,
              commercial, and industrial projects. Our seasoned team delivers
              top-notch service, prioritizing safety and reliability. Count on
              us to meet all of your electrical needs!
            </SubTitle>

            <Link to="contact" smooth duration={300} offset={-100}>
              <HomeButton>Get A Free Quote Today</HomeButton>
            </Link>
          </HomeDescriptionContainer>
          <HomePictureContainer>
            <img
              loading="lazy"
              src="../images/IMG_9724compress.jpg"
              alt="OlemPic"
              style={{
                width: '90%',
                height: 'auto',
                borderRadius: '2%',
              }}
            />
          </HomePictureContainer>
        </HomeMainWrapper>
      </HomeMainContainer>
    </div>
  );
}

export default Home;
