import React from 'react';
import Navbar from './Navbar';

import {
  AppHeader,
  HeaderMainContainer,
} from '../styledComponents/StyledComponents';

function Header() {
  return (
    <AppHeader>
      <HeaderMainContainer>
        <img
          loading="lazy"
          src="../images/HeaderLogo.png"
          alt="HeaderLogo"
          style={{ width: '300px', height: 'auto' }}
        />
        <Navbar />
      </HeaderMainContainer>
    </AppHeader>
  );
}

export default Header;
