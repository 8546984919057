import React from 'react';
import { MdLocationOn } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';

import {
  StickyBannerTopBar,
  StickyBannerCallNow,
  StickyBannerComboDiv,
  StickyBannerCallNowSpan,
  StickyBannerCallNowNumber,
  StickyBannerEmail,
  StickyBannerImgIcon,
} from '../styledComponents/StyledComponents';

function StickyBanner() {
  return (
    <StickyBannerTopBar>
      <>
        <StickyBannerComboDiv>
          <StickyBannerImgIcon>
            <MdEmail />
          </StickyBannerImgIcon>
          <StickyBannerEmail>OlemsElectric@gmail.com</StickyBannerEmail>
          <StickyBannerImgIcon>
            <MdLocationOn />
          </StickyBannerImgIcon>
          <>New Jersey, USA</>
        </StickyBannerComboDiv>
        <StickyBannerCallNow>
          <StickyBannerCallNowSpan>Call Now:</StickyBannerCallNowSpan>
          <StickyBannerCallNow>
            <StickyBannerCallNowNumber>
              973-699-0810 or 973-372-7444
            </StickyBannerCallNowNumber>
          </StickyBannerCallNow>
        </StickyBannerCallNow>
      </>
    </StickyBannerTopBar>
  );
}

export default StickyBanner;
