import React from 'react';
import { Link } from 'react-scroll';
import {
  NavbarContainer,
  StyledNavLink,
} from '../styledComponents/StyledComponents';

function Navbar() {
  return (
    <nav>
      <NavbarContainer>
        <Link to="home" smooth duration={300} offset={-100}>
          <StyledNavLink> Home </StyledNavLink>
        </Link>
        <Link to="services" smooth duration={300} offset={-100}>
          <StyledNavLink>Services</StyledNavLink>
        </Link>
        {/* <Link to="Testimonials" smooth duration={300} offset={-100}>
          <StyledNavLink>Testimonials</StyledNavLink>
        </Link> */}
        <Link to="service_area" smooth duration={300} offset={-100}>
          <StyledNavLink>Service Area</StyledNavLink>
        </Link>
        <Link to="contact" smooth duration={300} offset={-100}>
          <StyledNavLink>Contact</StyledNavLink>
        </Link>
      </NavbarContainer>
    </nav>
  );
}

export default Navbar;
