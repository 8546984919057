import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';
import { MdOutlineEmail } from 'react-icons/md';

// App.js Main styling
export const MainAppContainer = styled.div.attrs({
  className: 'MainAppContainer',
})`
  width: 100%;
  overflow-x: hidden;
`;
// Header
export const AppHeader = styled.div.attrs({
  className: 'AppHeader',
})`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: sticky;
  top: 0;

  @media (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const HeaderMainContainer = styled.div.attrs({
  className: 'HeaderMainContainer',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1200px;
`;

//StickyBanner
export const StickyBannerTopBar = styled.div.attrs({
  className: 'StickyBannerTopBar',
})`
  background-color: #223a66;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 200px;
  max-width: 100%;
  line-height: 1.6;
`;

export const StickyBannerComboDiv = styled.div.attrs({
  className: 'StickyBannerEmailLocation',
})`
  font-weight: 400;
  font-size: 16px;
  color: #fff;

  display: flex;
  align-items: center;
  margin-right: auto;
`;
export const StickyBannerEmail = styled.div.attrs({
  className: 'StickyBannerEmail',
})`
  margin-right: 3rem;
`;

export const StickyBannerCallNow = styled.div.attrs({
  className: 'StickyBannerCallNow',
})`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const StickyBannerCallNowSpan = styled.span.attrs({
  className: 'StickyBannerCallNowSpan',
})`
  padding-right: 5px;
  font-weight: 700;
  color: #fff;
  font-size: 20px;
`;
export const StickyBannerCallNowNumber = styled.span.attrs({
  className: 'StickyBannerCallNowNumber',
})`
  font-weight: 700;
  color: #fff;
  font-size: 26px;
  padding-right: 5px;
`;

export const StickyBannerImgIcon = styled.span.attrs({
  className: 'StickyImgIcon',
})`
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 25px;
  text-align: center;
  color: #fff;
  margin-right: -13px;
`;

// Nav
export const NavbarContainer = styled.ul.attrs({
  className: 'NavbarContainer',
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px; //was 32 previously
  padding: 0 6px;
  list-style: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const StyledNavLink = styled.a.attrs({
  className: 'StyledNavLink',
})`
  color: black;
  font-family: 'Exo', sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    color: #d4192c; //red
  }
`;

//Home
export const HomeMainContainer = styled.div.attrs({
  className: 'HomeMainContainer',
})`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #f4f9fc;
  padding: 100px 30px;

  @media (min-width: 961px) {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 960px) {
    padding: 66px 16px;
  }

  @media (max-width: 640px) {
    padding: 32px 16px;
  }

  @media (max-width: 363px) {
    padding: 32px 16px;
  }
`;

export const HomeMainWrapper = styled.div.attrs({
  className: 'HomeMainWrapper',
})`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const HomeDescriptionContainer = styled.div.attrs({
  className: 'HomeDescriptionContainer',
})`
  width: 100%;
  order: 1; // Content order on larger screens

  @media (max-width: 960px) {
    order: 2; // Content order on smaller screens
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 640px) {
    order: 2; // Content order on smaller screens
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const HomePictureContainer = styled.div.attrs({
  className: 'HomePictureContainer',
})`
  width: 100%;
  display: flex;
  order: 2;
  justify-content: end;
  gap: 12px;

  @media (max-width: 960px) {
    order: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; //previous 80px
  }

  @media (max-width: 640px) {
    margin-bottom: 5px; //previous 30px
  }
`;

export const Divider = styled.div.attrs({
  className: 'Divider',
})`
  font-weight: bold;
  font-size: 58px;
  line-height: 68px;
  margin-bottom: 15px;
  color: #d4192c;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 32px;
  }
`;

export const TitleName = styled.div.attrs({
  className: 'TitleName',
})`
  font-weight: 900;
  font-size: 48px;
  line-height: 68px;
  color: #223a66;//NavyBlue

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 1102px){
    //font-weight: 700;
    font-size: 46px;
    line-height: 66px;
    @media (max-width: 960px) {
      text-align: center;
  }

  @media (max-width: 640px) {
    font-size: 44px;
    line-height: 48px;
    margin-bottom: 8px;
  }
`;

export const SubTitle = styled.div.attrs({
  className: 'SubTitle',
})`
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 42px;
  color: #546a7d;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 32px;
  }
`;
export const Slogan = styled.div.attrs({
  className: 'Slogan',
})`
  font-size: 18px;
  line-height: 32px;
  margin-bottom: -3px;
  color: #546a7d;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 32px;
  }
`;

export const HomeButton = styled.button`
  padding: 10px 20px;
  background-color: #d4192c;
  color: #fff;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #223a66;
  }
`;

//Footer
export const Copyright = styled.p.attrs({
  className: 'Copyright',
})`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
`;

export const FooterMainContainer = styled.div.attrs({
  className: 'FooterMainContainer',
})`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  background-color: #f4f9fc; //lightGreyBlue
`;

export const FooterWrapper = styled.footer.attrs({
  className: 'FooterWrapper',
})`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 1rem;
  color: #223a66;
`;

export const FooterSections = styled.div.attrs({
  className: 'FooterSections',
})`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const FooterSectionOne = styled.h1.attrs({
  className: 'FooterSectionOne',
})``;
export const FooterSectionTwo = styled.div.attrs({
  className: 'FooterSectionTwo',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #223a66;
`;

export const FooterSectionTwoItems = styled.div.attrs({
  className: 'FooterSectionTwo',
})`
  font-size: 16px;

  > div:not(:last-child) {
    margin-bottom: 25px;
  }

  > div {
    display: flex;
    align-items: center;
    color: #223a66;
  }

  svg {
    margin-right: 10px;
    font-size: 26px;
  }

  a {
    color: #223a66;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FooterSectionThree = styled.h1.attrs({
  className: 'FooterSectionThree',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const FooterLink = styled.a.attrs({
  className: 'FooterLink',
})`
  color: #223a66;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-1px);
    color: #d4192c;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const FooterNav = styled.nav.attrs({
  className: 'FooterNav',
})`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  padding: 0 0.5rem;
  font-size: 16px;
  color: #223a66;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;
export const FooterLogo = styled.div.attrs({
  className: 'FooterLogo',
})`
  margin-bottom: 1.1rem;
  padding-left: 12px;
`;

export const FooterName = styled.h1.attrs({
  className: 'FooterName',
})`
  font-weight: 600;
  font-size: 20px;
  color: #223a66;
  text-transform: uppercase;
`;

//Service Area
export const AreaMainContainer = styled.div.attrs({
  className: 'AreaMainContainer',
})`
  width: 100%;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  background-color: #f4f9fc; //lightGreyBlue
`;

export const AreaWrapper = styled.div.attrs({
  className: 'AreaWrapper',
})`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 1rem;
  color: #223a66;
`;

export const AreaMainTitle = styled.h1.attrs({
  className: 'AreaMainTitle',
})`
  font-size: 28px;
  font-weight: 600;
  color: #223a66;
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
`;

//Contact
export const ContactMainContainer = styled.div.attrs({
  className: 'ContactMainContainer',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  margin-bottom: 30px;
`;

export const ContactWrapper = styled.div.attrs({
  className: 'ContactWrapper',
})`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  @media (max-width: 1099px) {
    max-width: 555px;
    padding: 10px 36px 0;
  }

  @media (max-width: 768px) {
    max-width: 475px;
    padding: 10px 36px;
  }
`;

export const ContactMainTitle = styled.div.attrs({
  className: 'ContactMainTitle',
})`
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
`;

export const ContactHubContainer = styled.div.attrs({
  className: 'ContactHubContainer',
})`
  width: 100%;
  display: flex;
  margin-top: 30px;
  gap: 30px;
  padding: 18px 36px;
  z-index: 1;
  }

  @media (max-width: 1099px) {
    max-width: 535px;
    padding: 20px 36px 0;
  }

  @media (max-width: 768px) {
    max-width: 400px;
    padding: 20px 36px 0;
  }

  @media (max-width: 500px) {
    max-width: 330px;
    padding: 20px 36px 0;
  }
`;
export const ContactHubMediaContainer = styled.div.attrs({
  className: 'ContactHubMediaContainer',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;

  @media (max-width: 1099px) {
    display: none;
  }
`;
export const ContactHubMediaHeader = styled.h2.attrs({
  className: 'ContactHubMediaHeader',
})`
  color: #fff;
  background-color: #223a66; //NavyBlue
  font-size: 40px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 2px;
  transform: translate(40px, 80px);
  padding: 20px 70px;

  display: flex;
  align-items: center; /* Align children vertically centered */
`;

export const EmailIcon = styled(MdOutlineEmail)`
  margin-right: 2px;
  font-size: 32px;
  color: #d4192c;
`;
export const ContactHubMediaBox = styled.h2.attrs({
  className: 'ContactHubMediaBox',
})`
  padding: 150px 40px 0;
  justify-content: center;
`;
export const ContactHubMediaVerticalHeader = styled.h2.attrs({
  className: 'ContactHubMediaVerticalHeader',
})`
  display: none;

  @media (max-width: 1099px) {
    display: block;
    color: ${({ theme }) => theme.text_primary};
    font-size: 26px;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    justify-content: center;
  }
`;
export const ContactHubMediaBlob = styled.div.attrs({
  className: 'ContactHubMediaBlob',
})`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  overflow: hidden;
`;

export const ContactHubFormContainer = styled.div.attrs({
  className: 'ContactHubFormContainer ',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 434px;
`;

export const ContactHubFormInput = styled.input.attrs({
  className: 'ContactHubFormInput',
})`
  width: 100%;
  height: 34px;
  background-color: #f4f9fc; //Lightblue for input
  color: #3f4549; //grey lettering when typing
  letter-spacing: 1px;
  padding: 8px;
  margin-bottom: 15px;
  border: 1.3px solid #e8ecef;
  &:focus {
    border: 2px solid lightblue;
    outline: none;
  }
`;

export const ContactHubFormMessageInput = styled.textarea.attrs({
  className: 'ContactHubFormMessageInput',
})`
  width: 100%;
  background-color: #f4f9fc; //Lightblue for input
  color: #3f4549; //grey lettering when typing
  letter-spacing: 1px;
  padding: 8px;
  margin-bottom: 15px;
  border: 1.3px solid #e8ecef;

  &:focus {
    border: 2px solid lightblue;
    outline: none;
  }
`;

export const ContactHubSendButton = styled.button.attrs({
  className: 'ContactHubSendButton',
})`
width: 100%;
height: 44px;
background-color:#223a66;
color: white;
overflow: hidden;
cursor: pointer;
border: .02px solid ${({ theme }) => theme.border};
// border-radius: 6px; //original
border-radius: 36px;

transition: all .2s ease-in-out;

  &:hover {
    background-color: #d4192c;
  }}
`;

export const ContactHubAltSendButton = styled.div.attrs({
  className: 'ContactHubAltSendButton',
})`
  width: 100%;
  height: 30px;
`;

export const ContactHubAltSendButtonText = styled.span.attrs({
  className: 'ContactHubAltSendButtonText',
})`
  display: block;
  padding-top: 6px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
`;
export const ContactImgSVGWrapper = styled.div.attrs({
  className: 'ContactImgSVGWrapper',
})`
  justify-self: center;
  position: absolute;
  top: 0;
  bottom: -6px;
  fill: #023f92;
  width: 92%;
  z-index: -1;
  pointer-events: none;
`;

//Services
export const ServicesMainContainer = styled.div.attrs({
  className: 'ServicesMainContainer',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
`;

export const ServicesWrapper = styled.div.attrs({
  className: 'ServicesWrapper',
})`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const ServicesMainTitle = styled.div.attrs({
  className: 'ServicesMainTitle',
})`
  font-size: 28px;
  font-weight: 600;
  color: #223a66;
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
`;

export const ServicesHubContainer = styled.div.attrs({
  className: 'ServicesHubContainer',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 30px;

  gap: 30px;
  justify-content: center;
`;

export const ServicesCard = styled.div.attrs({
  className: 'ServicesCard',
})`
  width: 100%;
  max-width: 535px;
  background-color: ${({ theme }) => theme.card};
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 18px 36px;

  &:hover {
    background-color: ${({ theme }) => theme.primary + 95};
  }

  @media (max-width: 768px) {
    max-width: 400px;
    padding: 10px 36px;
  }

  @media (max-width: 500px) {
    max-width: 330px;
    padding: 10px 36px;
  }
`;

export const ServicesHubTitle = styled.h2.attrs({
  className: 'ServicesHubTitle',
})`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.text_primary};
  margin-top: 5px;
`;

export const ServicesParagraph = styled.p.attrs({
  className: 'ServicesParagraph',
})`
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 12px;
  color: #3f4549;

  @media (max-width: 960px) {
    font-size: 14px;
    line-height: 26px;
  }

  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 26px;
  }
`;

export const ServicesTextContent = styled.div.attrs({
  className: 'ServicesTextContent',
})`
  padding-left: 20px;
`;

export const ServicesImgIcon = styled.span.attrs({
  className: 'ServicesImgIcon',
})`
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 40px;
  text-align: center;
  color: #223a66;
`;

export const ServicesMediaContent = styled.div.attrs({
  className: 'ServicesMediaContent',
})`
  display: flex;
`;
