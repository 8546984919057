import React from 'react';
import { MdHouse } from 'react-icons/md';
import { DiScrum } from 'react-icons/di';
import { MdStoreMallDirectory } from 'react-icons/md';
import { MdFactory } from 'react-icons/md';
import {
  ServicesMainContainer,
  ServicesWrapper,
  ServicesMainTitle,
  ServicesHubContainer,
  ServicesImgIcon,
  ServicesCard,
  ServicesHubTitle,
  ServicesMediaContent,
  ServicesParagraph,
  ServicesTextContent,
} from '../styledComponents/StyledComponents';

function Services() {
  return (
    <>
      <ServicesMainContainer id="services">
        <ServicesWrapper>
          <ServicesMainTitle>SERVICES</ServicesMainTitle>
          <ServicesHubContainer>
            <ServicesCard>
              <ServicesMediaContent>
                <ServicesImgIcon>
                  <MdHouse />
                </ServicesImgIcon>
                <ServicesTextContent>
                  <ServicesHubTitle>Residential</ServicesHubTitle>
                  <ServicesParagraph>
                    Your home deserves top-notch care. We specialize in upgrades
                    and repairs, covering outlets, doorbells, lighting, pools,
                    panel services, and much more! Let us enhance your sanctuary
                    with our expert touch. Trust us for all your home
                    improvement needs and experience the difference with our
                    dedicated service.
                  </ServicesParagraph>
                </ServicesTextContent>
              </ServicesMediaContent>
            </ServicesCard>
            <ServicesCard>
              <ServicesMediaContent>
                <ServicesImgIcon>
                  <MdStoreMallDirectory />
                </ServicesImgIcon>
                <ServicesTextContent>
                  <ServicesHubTitle>Commercial</ServicesHubTitle>
                  <ServicesParagraph>
                    Whether you run a restaurant, hotel, apartment complex, or
                    manage multiple locations for your small business, Olem's
                    Electric LLC is your go-to for electrical solutions. Our
                    skilled team handles everything from ceiling fan and
                    recessed lighting installations to complex panel upgrades,
                    ensuring efficient and tailor-made service for commercial
                    properties across New Jersey.
                  </ServicesParagraph>
                </ServicesTextContent>
              </ServicesMediaContent>
            </ServicesCard>
            {/* <ServicesCard>
              <ServicesMediaContent>
                <ServicesImgIcon>
                  <MdFactory />
                </ServicesImgIcon>
                <ServicesTextContent>
                  <ServicesHubTitle>Industrial</ServicesHubTitle>
                  <ServicesParagraph>
                    I have experience in designing, implementing, and managing
                    efficient and secure databases using MySQL and SQL. This
                    ensures the constant protection and accessibility of your
                    data.
                  </ServicesParagraph>
                </ServicesTextContent>
              </ServicesMediaContent>
            </ServicesCard> */}
          </ServicesHubContainer>
        </ServicesWrapper>
      </ServicesMainContainer>
    </>
  );
}

export default Services;
