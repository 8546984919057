import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { MdOutlineEmail } from 'react-icons/md';
import {
  ContactMainContainer,
  ContactWrapper,
  ContactMainTitle,
  ContactHubContainer,
  ContactHubMediaContainer,
  ContactHubFormContainer,
  ContactHubMediaHeader,
  ContactHubMediaBlob,
  ContactHubFormInput,
  ContactHubFormMessageInput,
  ContactHubSendButton,
  ContactHubMediaVerticalHeader,
  ContactHubAltSendButton,
  ContactHubAltSendButtonText,
  ContactImgSVGWrapper,
  ContactHubMediaBox,
  EmailIcon,
} from '../styledComponents/StyledComponents';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); //prevent refresh

    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then((result) => {
        console.log(result.text);
        e.target.reset();
        toast.success('Success! Message sent');
      })
      .catch((error) => {
        console.log(error.text);
        toast.error('Oops! Message failed');
      });
  };

  return (
    <ContactMainContainer id="contact">
      <ContactWrapper>
        <ContactMainTitle>Contact</ContactMainTitle>
        <ContactHubContainer>
          <ContactHubMediaContainer>
            <ContactHubMediaBlob>
              <ContactHubMediaBox>
                <ContactHubMediaHeader>
                  <EmailIcon>
                    <MdOutlineEmail />
                  </EmailIcon>
                  Book Today!
                </ContactHubMediaHeader>
                <ContactImgSVGWrapper>
                  <img
                    loading="lazy"
                    src="images/WallShots/jawnski.jpg"
                    alt="HeaderLogo"
                    style={{
                      width: '100%',
                      height: 'auto',
                      marginTop: '-115px',
                    }}
                  />
                </ContactImgSVGWrapper>
              </ContactHubMediaBox>
            </ContactHubMediaBlob>
          </ContactHubMediaContainer>
          <ContactHubFormContainer>
            <ContactHubMediaVerticalHeader>
              Book Today!
            </ContactHubMediaVerticalHeader>
            <form ref={form} onSubmit={sendEmail}>
              <ContactHubFormInput
                type="text"
                name="user_name"
                placeholder="FULL NAME"
                required
              />
              <ContactHubFormInput
                type="email"
                name="user_email"
                placeholder="EMAIL"
                required
              />

              <ContactHubFormInput
                type="text"
                name="subject"
                placeholder="SUBJECT"
              />
              <ContactHubFormMessageInput
                name="message"
                rows="6"
                placeholder="MESSAGE"
                required
              />
              <ContactHubSendButton type="submit" value="SEND">
                <ContactHubAltSendButton>
                  <ContactHubAltSendButtonText>
                    Send
                  </ContactHubAltSendButtonText>
                </ContactHubAltSendButton>
              </ContactHubSendButton>
            </form>
          </ContactHubFormContainer>
        </ContactHubContainer>
      </ContactWrapper>
    </ContactMainContainer>
  );
}

export default Contact;
