import React from 'react';
import { Link } from 'react-scroll';
import { MdLocationOn, MdPhone, MdEmail } from 'react-icons/md';
import {
  Copyright,
  FooterMainContainer,
  FooterLink,
  FooterSectionOne,
  FooterSectionTwo,
  FooterSectionThree,
  FooterNav,
  FooterWrapper,
  FooterSections,
  FooterLogo,
  FooterName,
  FooterSectionTwoItems,
  // TrimLogoOlemsCapture,
} from '../styledComponents/StyledComponents';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <FooterMainContainer>
      <FooterWrapper>
        <FooterSections>
          <FooterSectionOne>
            <FooterLogo>
              <img
                loading="lazy"
                src="../images/TrimLogoOlemsCapture.png"
                alt="FooterLogo"
                style={{ width: '300px', height: 'auto' }}
              />
            </FooterLogo>
            <FooterNav>
              <Link to="home" smooth duration={300} offset={-100}>
                <FooterLink>Home </FooterLink>
                <span>|</span>
              </Link>
              <Link to="services" smooth duration={300} offset={-100}>
                <FooterLink>Services </FooterLink>
                <span>|</span>
              </Link>
              {/* <Link to="testimonials" smooth duration={300} offset={-100}>
                <FooterLink>Testimonials </FooterLink> <span>|</span>
              </Link> */}
              <Link to="service_area" smooth duration={300} offset={-100}>
                <FooterLink>Service Area </FooterLink>
              </Link>
            </FooterNav>
          </FooterSectionOne>
          <FooterSectionTwo>
            <FooterSectionTwoItems>
              <div>
                <MdLocationOn />
                <span>New Jersey, USA</span>
              </div>
              <div>
                <MdPhone />
                <span>973-699-0810 or 973-372-7444</span>
              </div>
              <div>
                <MdEmail />
                <a href="mailto:OlemsElectric@gmail.com">
                  OlemsElectric@gmail.com
                </a>
              </div>
            </FooterSectionTwoItems>
          </FooterSectionTwo>
          <FooterSectionThree>
            <a
              href="https://www.bbb.org/us/nj/bridgewater/profile/electrician/olems-electric-llc-0221-90229239"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="BBB"
            >
              <img
                loading="lazy"
                src="../images/BBBtrans.png"
                alt="BBB"
                style={{
                  width: '180px',
                  height: 'auto',
                  paddingBottom: '25px',
                  marginTop: '-10px',
                }}
              />
            </a>
            <FooterName>We Accept</FooterName>
            <img
              loading="lazy"
              src="../images/CheckCash.png"
              alt="CashOrCheck"
              style={{ width: '100px', height: 'auto' }}
            />
          </FooterSectionThree>
        </FooterSections>
        <Copyright>
          COPYRIGHT &copy; {currentYear} Olem's Electric LLC. All rights
          reserved.
        </Copyright>
      </FooterWrapper>
    </FooterMainContainer>
  );
}

export default Footer;
