import React from 'react';
import Header from './components/Header';
import StickyBanner from './components/StickyBanner';
import Home from './components/Home';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ServiceArea from './components/ServiceArea';
import { MainAppContainer } from './styledComponents/StyledComponents';
import Testimonials from './components/Testimonials';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Services from './components/Services';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Router>
        <StickyBanner />
        <Header />
        <MainAppContainer>
          <Home />
          <Services />
          <Testimonials />
          <ServiceArea />
          <Contact />
          <Footer />
        </MainAppContainer>
      </Router>
    </>
  );
}

export default App;
